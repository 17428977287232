import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import GiveButton from "../components/give-button";
import TestimonialSlider from "../components/testimonial-slider";

import "../components/main.css";

const TheVisionPage = ({ data }) => (
  <Layout color={true}>
    <SEO title="The Vision" keywords={[`missoula`, `sovereign hope`, `church`]} />
    <BackgroundImage
      style={{
        backgroundSize: 'cover',
        minHeight: '538px',
      }}
      Tag="section"
      fluid={data.theVisionHeader.childImageSharp.fluid}
    >
      <div id="vision-header">
        <div id="text">
          <h2 className="color-blue">Imagine How the Gospel Could Change Missoula</h2>
          <p className="poppins">Seeing the gospel flourish in the life of a church is what’s best for Missoula. It shapes our efforts for justice, stirs our hands to service, and opens our hearts to compassion. For this to happen we need individuals committed to seeing God glorified not only in the church, but as the church in our homes, our parks, our grocery stores, schools, and workplaces.</p>
        </div>
        <div id="video">
          <div id="wrapper">
            <iframe
              title="video"
              src="https://www.youtube.com/embed/CKW2sfOpglA?modestbranding=1&rel=0&iv_load_policy=3"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </BackgroundImage>
    <BackgroundImage
      style={{
        minHeight: '671px',
        backgroundPosition: 'right bottom',
      }}
      Tag="section"
      fluid={data.theVisionSubHeader.childImageSharp.fluid}
    >
      <div id="vision-sub-header">
	<div id="video">
          <div id="wrapper">
            <iframe
              title="video"
              src="https://www.youtube.com/embed/uZ9XqzzI7ZQ?modestbranding=1&rel=0&iv_load_policy=3"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
	<div id="text">
          <h1 className="color-white">A Church Building</h1>
          <p className="poppins color-white">In the next 50 years we want to continue to be a church building momentum; a church building worship; a church building community; and a church building a legacy. And essential to all of this, we need a foothold in Missoula as a center for this vision. We need <i>A Church Building.</i></p>
        </div>
      </div>
    </BackgroundImage>
    <div id="pray-give-partner">
      <div id="content">
        <div id="text">
          <h2 className="color-blue">Total Cost: $2 million</h2>
          <p className="poppins">To have a legacy like this we need Missoulians eager to pray, happy to give, and ready to partner with us.</p>
          <GiveButton />
        </div>
        <div id="images">
          <div id="pray">
            <Img fixed={data.pray.childImageSharp.fixed} />
            <p className="poppins extra-large light color-blue">Pray</p>
          </div>
          <div id="give">
            <Img fixed={data.give.childImageSharp.fixed} />
            <p className="poppins extra-large light color-blue">Give</p>
          </div>
          <div id="partner">
            <Img fixed={data.partner.childImageSharp.fixed} />
            <p className="poppins extra-large light color-blue">Partner</p>
          </div>
        </div>
      </div>
    </div>
    <TestimonialSlider
      slides={[
        {
          fluidImage: data.slideOne.childImageSharp.fluid,
          quoteText: "I am a junior at the University of Montana studying Social Work, and I am beyond excited to partner with Sovereign Hope Church in following my call to serve God and love others.”",
          quoteAuthor: "Katie Klassen",
          quoteColorClass: "yellow",
          class: "klassen"
        },
        {
          fluidImage: data.slideTwo.childImageSharp.fluid,
          quoteText: "We are blessed with the knowledge wherever our future home may be, the church it houses has a rock solid foundation, the gospel of the resurrected Son, Jesus Christ, our Savior.”",
          quoteAuthor: "Greg & Pam Winstone",
          quoteColorClass: "pink",
          class: "winstone"
        },
        {
          fluidImage: data.slideThree.childImageSharp.fluid,
          quoteText: "We want our lifestyle and use of resources to be a reflection of Christ's joyful, costly sacrifice for us.”",
          quoteAuthor: "Daniel & Patty Bourassa",
          quoteColorClass: "green",
          class: "bourassa"
        }
      ]}
    />
    <BackgroundImage
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
      }}
      Tag="section"
      classId="dfpiasa"
      className="our-goals"
      fluid={data.ourGoals.childImageSharp.fluid}
    >
      <div id="text" className="poppins small">
        <h1 className="color-blue">Our Goals</h1>
        <h3 className="color-blue">Community Involvement</h3>
        <p>We want you to be looking for ways to serve our city, and we want our building to help you and your community group do that. Examples of what it could look like for members to do this include: community lunches, hosting free child care nights, or providing a meeting place for planning of service projects. A long-term facility in a specific neighborhood will bear much fruit in that area as relationships and community develop.</p>
        <h3 className="color-blue">Community Meeting Spaces</h3>
        <p>Opportunities for neighborhood councils or school boards.</p>
        <h3 className="color-blue">Non-Profit and Mercy Efforts</h3>
        <p>Offer low-cost office space to Nonprofits who are aligned with our values and vision. These could include adoption or foster services, social justice ventures, and businesses seeking to benefit Missoula economically.</p>
        <h3 className="color-blue">Emergency Relief</h3>
        <p>Allows our members to serve emergency needs in our community with our facility.</p>
        <a className="button background-blue color-white small" href="http://sovereignhope.church/pdf/A-Church-Building-booklet-web.pdf">Campaign Booklet</a>
      </div>
    </BackgroundImage>
    <BackgroundImage
      style={{
        minHeight: `709px`,
      }}
      Tag="section"
      fluid={data.sermonBackground.childImageSharp.fluid}
    >
      <div id="sermons">
        <div id="text" className="color-white">
          <h1 className="ridiculous">Sermons</h1>
          <p className="poppins">Watch messages to learn more about our vision for a gospel legacy in Missoula.</p>
        </div>
        <div id="videos">
          <div id="video">
            <div id="wrapper">
              <iframe
                title="video"
                src="https://www.youtube.com/embed/A1xgKl7UFIE?modestbranding=1&rel=0&iv_load_policy=3"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
          <div id="video">
            <div id="wrapper">
              <iframe
                title="video"
                src="https://www.youtube.com/embed/IdtsvyVkozs?modestbranding=1&rel=0&iv_load_policy=3"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  </Layout>
)

export const query = graphql`
  query {
    theVisionHeader: file(relativePath: { eq: "the-vision-header.png" }) {
      childImageSharp {
        fluid(maxHeight: 486) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    theVisionSubHeader: file(relativePath: { eq: "building-render.png" }) {
      childImageSharp {
        fluid(maxHeight: 671) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    pray: file(relativePath: { eq: "pray.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 125, width: 124) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    give: file(relativePath: { eq: "give.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 125, width: 124) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    partner: file(relativePath: { eq: "partner.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 125, width: 124) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    slideOne: file(relativePath: { eq: "vision-testimonial-slide-1.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    slideTwo: file(relativePath: { eq: "vision-testimonial-slide-2.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    slideThree: file(relativePath: { eq: "vision-testimonial-slide-3.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    ourGoals: file(relativePath: { eq: "our-goals.png" }) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 1108, maxWidth: 1296) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    sermonBackground: file(relativePath: { eq: "sermon-background.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 720, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
  }
`

export default TheVisionPage
